// import React, { useState } from 'react';
// import { GoogleMap,GoogleAd , Marker, LoadScript, InfoWindow } from '@react-google-maps/api';

// // Extensive Andheri Property Data
// const realEstateData = [
//   {
//     id: 1,
//     name: "Luxe Residency",
//     position: { lat: 19.1334, lng: 72.8397 },
//     address: "Andheri West, Mumbai",
//     rent: "₹35,000/month",
//     type: "1 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "550 sq ft"
//   },
//   {
//     id: 2,
//     name: "Tech Tower",
//     position: { lat: 19.1376, lng: 72.8435 },
//     address: "MIDC Andheri East, Mumbai",
//     rent: "₹25,000/month",
//     type: "1 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "600 sq ft"
//   },
//   {
//     id: 3,
//     name: "Seaside Apartment",
//     position: { lat: 19.1390, lng: 72.8342 },
//     address: "Versova, Andheri West",
//     rent: "₹65,000/month",
//     type: "2 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "950 sq ft"
//   },
//   {
//     id: 4,
//     name: "Skyline Heights",
//     position: { lat: 19.1352, lng: 72.8412 },
//     address: "Lokhandwala, Andheri West",
//     rent: "₹50,000/month",
//     type: "2 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "900 sq ft"
//   },
//   {
//     id: 5,
//     name: "Elite Residency",
//     position: { lat: 19.1283, lng: 72.8410 },
//     address: "Andheri West, Mumbai",
//     rent: "₹32,000/month",
//     type: "1 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "450 sq ft"
//   },
//   {
//     id: 6,
//     name: "Green Meadows",
//     position: { lat: 19.1320, lng: 72.8480 },
//     address: "Andheri East, Mumbai",
//     rent: "₹40,000/month",
//     type: "2 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "750 sq ft"
//   },
//   {
//     id: 7,
//     name: "Urban Oasis",
//     position: { lat: 19.1345, lng: 72.8423 },
//     address: "Lokhandwala, Andheri West",
//     rent: "₹55,000/month",
//     type: "2 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "850 sq ft"
//   },
//   {
//     id: 8,
//     name: "Sunrise Towers",
//     position: { lat: 19.1358, lng: 72.8376 },
//     address: "Veera Desai Road, Andheri West",
//     rent: "₹28,000/month",
//     type: "1 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "500 sq ft"
//   },
//   {
//     id: 9,
//     name: "Silver Sands",
//     position: { lat: 19.1401, lng: 72.8330 },
//     address: "Versova, Andheri West",
//     rent: "₹70,000/month",
//     type: "2 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "1,100 sq ft"
//   },
//   {
//     id: 10,
//     name: "Infinity Residences",
//     position: { lat: 19.1368, lng: 72.8452 },
//     address: "Andheri East, Mumbai",
//     rent: "₹48,000/month",
//     type: "2 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "850 sq ft"
//   },
//   {
//     id: 11,
//     name: "Cosmos Heights",
//     position: { lat: 19.1312, lng: 72.8385 },
//     address: "Andheri West, Mumbai",
//     rent: "₹30,000/month",
//     type: "1 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "600 sq ft"
//   },
//   {
//     id: 12,
//     name: "Palm Grove",
//     position: { lat: 19.1295, lng: 72.8399 },
//     address: "Juhu Lane, Andheri West",
//     rent: "₹37,000/month",
//     type: "1 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "550 sq ft"
//   },
//   {
//     id: 13,
//     name: "Crystal Residency",
//     position: { lat: 19.1387, lng: 72.8420 },
//     address: "MIDC, Andheri East",
//     rent: "₹42,000/month",
//     type: "2 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "800 sq ft"
//   },
//   {
//     id: 14,
//     name: "Oceanic Towers",
//     position: { lat: 19.1412, lng: 72.8351 },
//     address: "Versova Beach, Andheri West",
//     rent: "₹75,000/month",
//     type: "2 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "1,200 sq ft"
//   },
//   {
//     id: 15,
//     name: "Golden Gate Apartments",
//     position: { lat: 19.1343, lng: 72.8391 },
//     address: "Lokhandwala, Andheri West",
//     rent: "₹45,000/month",
//     type: "2 BHK",
//     furnished: "Furnished",
//     occupancy: "Double Occupancy",
//     area: "850 sq ft"
//   },
//   {
//     id: 16,
//     name: "Maple Residency",
//     position: { lat: 19.1360, lng: 72.8465 },
//     address: "Andheri East, Mumbai",
//     rent: "₹38,000/month",
//     type: "1 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Single Occupancy",
//     area: "500 sq ft"
//   },
//   {
//     id: 17,
//     name: "Pearl Mansion",
//     position: { lat: 19.1317, lng: 72.8378 },
//     address: "Andheri West, Mumbai",
//     rent: "₹40,000/month",
//     type: "1 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "600 sq ft"
//   },
//   {
//     id: 18,
//     name: "Amber Residency",
//     position: { lat: 19.1339, lng: 72.8440 },
//     address: "Andheri East, Mumbai",
//     rent: "₹35,000/month",
//     type: "1 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "500 sq ft"
//   },
//   {
//     id: 19,
//     name: "Regal Heights",
//     position: { lat: 19.1289, lng: 72.8417 },
//     address: "Andheri West, Mumbai",
//     rent: "₹52,000/month",
//     type: "2 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "900 sq ft"
//   },
//   {
//     id: 20,
//     name: "Azure Towers",
//     position: { lat: 19.1395, lng: 72.8337 },
//     address: "Versova, Andheri West",
//     rent: "₹60,000/month",
//     type: "2 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "1,000 sq ft"
//   },
//   {
//     id: 21,
//     name: "Platinum Residency",
//     position: { lat: 19.1305, lng: 72.8432 },
//     address: "Andheri East, Mumbai",
//     rent: "₹50,000/month",
//     type: "2 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "850 sq ft"
//   },
//   {
//     id: 22,
//     name: "Orchid Residency",
//     position: { lat: 19.1351, lng: 72.8415 },
//     address: "Andheri West, Mumbai",
//     rent: "₹30,000/month",
//     type: "1 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "550 sq ft"
//   },
//   {
//     id: 23,
//     name: "Willow Tower",
//     position: { lat: 19.1363, lng: 72.8458 },
//     address: "Andheri East, Mumbai",
//     rent: "₹44,000/month",
//     type: "2 BHK",
//     furnished: "Semi-Furnished",
//     occupancy: "Double Occupancy",
//     area: "750 sq ft"
//   },
//   {
//     id: 24,
//     name: "Emerald Residency",
//     position: { lat: 19.1316, lng: 72.8362 },
//     address: "Andheri West, Mumbai",
//     rent: "₹33,000/month",
//     type: "1 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "500 sq ft"
//   },
//   {
//     id: 25,
//     name: "Coral Heights",
//     position: { lat: 19.1393, lng: 72.8321 },
//     address: "Versova Beach, Andheri West",
//     rent: "₹65,000/month",
//     type: "2 BHK",
//     furnished: "Furnished",
//     occupancy: "Single Occupancy",
//     area: "1,200 sq ft"
//   }
// ];

// // Helper function to calculate distance between two points
// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//   const R = 6371; // Radius of the Earth in km
//   const dLat = (lat2 - lat1) * Math.PI / 180;
//   const dLon = (lon2 - lon1) * Math.PI / 180;
//   const a = 
//     Math.sin(dLat/2) * Math.sin(dLat/2) +
//     Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
//     Math.sin(dLon/2) * Math.sin(dLon/2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
//   return R * c;
// };

// const AndherRealEstateMap = () => {
//   const [selectedProperty, setSelectedProperty] = useState(null);
//   const [nearestProperties, setNearestProperties] = useState([]);
//   const [mapClick, setMapClick] = useState(null);

//   const mapContainerStyle = {
//     width: '60%',
//     height: '100vh'
//   };

//   const center = {
//     lat: 19.1334,
//     lng: 72.8397
//   };

//   const handleMapClick = (event) => {
//     const clickedLat = event.latLng.lat();
//     const clickedLng = event.latLng.lng();
//     setMapClick({ lat: clickedLat, lng: clickedLng });

//     // Calculate distances and find 3 nearest properties
//     const distances = realEstateData.map(property => ({
//       ...property,
//       distance: calculateDistance(
//         clickedLat, 
//         clickedLng, 
//         property.position.lat, 
//         property.position.lng
//       )
//     }));

//     // Sort by distance and take top 3
//     const nearest = distances
//       .sort((a, b) => a.distance - b.distance)
//       .slice(0, 3);

//     setNearestProperties(nearest);
//   };

//   return (
//     <div className="flex h-screen">
//       <div className="w-1/4 p-4 bg-gray-100 overflow-y-auto">
//         <h2 className="text-2xl font-bold mb-4">Andheri Properties</h2>
//         {nearestProperties.length > 0 ? (
//           <div>
//             <h3 className="text-xl font-semibold mb-3">Nearest Properties</h3>
//             {nearestProperties.map(property => (
//               <div 
//                 key={property.id}
//                 onClick={() => setSelectedProperty(property)}
//                 className="bg-white p-3 mb-2 rounded shadow-sm cursor-pointer hover:bg-blue-50"
//               >
//                 <h4 className="font-bold">{property.name}</h4>
//                 <p className="text-sm text-gray-600">
//                   Distance: {property.distance.toFixed(2)} km | {property.price}
//                 </p>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div>
//             <h3 className="text-xl font-semibold mb-3">All Properties</h3>
//             {realEstateData.map(property => (
//               <div 
//                 key={property.id}
//                 onClick={() => setSelectedProperty(property)}
//                 className="bg-white p-3 mb-2 rounded shadow-sm cursor-pointer hover:bg-blue-50"
//               >
//                 <h4 className="font-bold">{property.name}</h4>
//                 <p className="text-sm text-gray-600">{property.address} | {property.price}</p>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <LoadScript
//         googleMapsApiKey="AIzaSyDztdh6amKLh1OgAiGKGAp3Y41Q2OJo8J0"

//       >
//         <GoogleMap
//           mapContainerStyle={mapContainerStyle}
//           center={center}
//           zoom={12}
//           onClick={handleMapClick}
//         >
//           {/* Click Point Marker */}
//           {mapClick && (
//             <Marker
//               position={mapClick}
//               icon={{
//                 url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
//               }}
//             />
//           )}

//           {/* Nearest Properties Markers */}
//           {nearestProperties.length > 0 
//             ? nearestProperties.map((property) => (
//                 <Marker
//                   key={property.id}
//                   position={property.position}
//                   onClick={() => setSelectedProperty(property)}
//                 />
//               ))
//             : realEstateData.map((property) => (
//                 <Marker
//                   key={property.id}
//                   position={property.position}
//                   onClick={() => setSelectedProperty(property)}
//                 />
//               ))
//           }
//         </GoogleMap>
//       </LoadScript>

//       {/* Property Details Modal */}
//       {selectedProperty && (
//         <div className="fixed top-0 right-0 w-1/4 h-full bg-white p-6 shadow-lg overflow-y-auto">
//           <button 
//             onClick={() => setSelectedProperty(null)}
//             className="absolute top-4 right-4 text-xl font-bold"
//           >
//             ×
//           </button>
//           <h3 className="text-2xl font-bold mb-4">{selectedProperty.name}</h3>
//           <div className="space-y-2">
//             <p><strong>Address:</strong> {selectedProperty.address}</p>
//             <p><strong>Price:</strong> {selectedProperty.price}</p>
//             <p><strong>Type:</strong> {selectedProperty.type}</p>
//             <p><strong>Bedrooms:</strong> {selectedProperty.bedrooms}</p>
//             <p><strong>Area:</strong> {selectedProperty.area}</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AndherRealEstateMap;

// import React from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// const MapComponent = () => {
//   const mapStyles = {
//     height: "400px",
//     width: "100%",
//   };

//   const defaultCenter = {
//     lat: 19.1197, // Latitude for Andheri
//     lng: 72.8466, // Longitude for Andheri
//   };

//   return (
//     <LoadScript googleMapsApiKey="AIzaSyDztdh6amKLh1OgAiGKGAp3Y41Q2OJo8J0">
//       <GoogleMap
//         mapContainerStyle={mapStyles}
//         center={defaultCenter}
//         zoom={14}
//       >
//         <Marker position={defaultCenter} />
//       </GoogleMap>
//     </LoadScript>
//   );
// };
// import React, { useState, useCallback, useMemo, useRef } from 'react';
// import { LoadScript, GoogleMap, Marker, Polygon } from '@react-google-maps/api';

// // Andheri Boundary Coordinates (approximate polygon)
// // const ANDHERI_BOUNDARY = [
// //   { lat: 19.096458, lng: 72.865311 },
// //   { lat: 19.116105, lng: 72.843749 },
// //   { lat: 19.130900, lng: 72.861107 },
// //   { lat: 19.130097, lng: 72.893395 },
// //   { lat: 19.107092, lng: 72.890969 },
// //   { lat: 19.096458, lng: 72.865311 }
// // ];

// // 19.116362, 72.868432

// const ANDHERI_BOUNDARY = [
//   { lat: 19.112386, lng: 72.845490 },
//   { lat: 19.119361, lng: 72.820256 },
//   { lat: 19.139309, lng: 72.801545 },
//   { lat: 19.165886, lng: 72.826094 },
//   { lat: 19.126497, lng: 72.847207 },
//   { lat: 19.112386, lng: 72.845490 }
// ];
// // 19.139796, 72.824204


// // Properties within Andheri
// *



// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//   const R = 6371;
//   const dLat = (lat2 - lat1) * Math.PI / 180;
//   const dLon = (lon2 - lon1) * Math.PI / 180;
//   const a = 
//     Math.sin(dLat/2) * Math.sin(dLat/2) +
//     Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
//     Math.sin(dLon/2) * Math.sin(dLon/2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
//   return R * c;
// };

// const AndheriRealEstateMap = () => {
//   const [selectedProperty, setSelectedProperty] = useState(null);
//   const [nearestProperties, setNearestProperties] = useState([]);
//   const [mapClick, setMapClick] = useState(null);
//   const [filterType, setFilterType] = useState('All');
//   const [showQuoteForm, setShowQuoteForm] = useState(false);
//   const mapRef = useRef(null);

//   const mapContainerStyle = {
//     width: '100%',
//     height: '100vh'
//   };

//   const center = {
//     lat: 19.135695,
//     lng: 72.824579
//   };

//   const handleMapClick = (event) => {
//     const clickedLat = event.latLng.lat();
//     const clickedLng = event.latLng.lng();
//     setMapClick({ lat: clickedLat, lng: clickedLng });

//     const distances = realEstateData
//       .filter(property => filterType === 'All' || property.property_type === filterType)
//       .map(property => ({
//         ...property,
//         distance: calculateDistance(
//           clickedLat, 
//           clickedLng, 
//           property.position.lat, 
//           property.position.lng
//         )
//       }));

//     const nearest = distances
//       .sort((a, b) => a.distance - b.distance)
//       .slice(0, 3);

//     setNearestProperties(nearest);
//   };

//   const defaultNearestProperties = useMemo(() => {
//     const centerDistances = realEstateData
//       .filter(property => filterType === 'All' || property.property_type === filterType)
//       .map(property => ({
//         ...property,
//         distance: calculateDistance(
//           center.lat, 
//           center.lng, 
//           property.position.lat, 
//           property.position.lng
//         )
//       }))
//       .sort((a, b) => a.distance - b.distance)
//       .slice(0, 3);

//     return centerDistances;
//   }, [filterType]);

//   const onMapLoad = useCallback((map) => {
//     mapRef.current = map;
//   }, []);

//   return (
//     <div className="flex h-screen bg-gray-100">
//       {/* Map Section - Left Side */}
//       <div className="w-2/3 h-full relative">
//         <LoadScript googleMapsApiKey="AIzaSyDztdh6amKLh1OgAiGKGAp3Y41Q2OJo8J0">
//           <GoogleMap
//             mapContainerStyle={mapContainerStyle}
//             center={center}
//             zoom={12}
//             onClick={handleMapClick}
//             onLoad={onMapLoad}
//             options={{
//               restriction: {
//                 latLngBounds: {
//                   north: 19.1576,
//                   south: 19.1190,
//                   east: 72.8736,
//                   west: 72.8208
//                 },
//                 strictBounds: true
//               }
//             }}
//           >
//             <Polygon
//               paths={ANDHERI_BOUNDARY}
//               options={{
//                 strokeColor: "#0000FF",
//                 strokeOpacity: 0.8,
//                 strokeWeight: 2,
//                 fillColor: "#0000FF",
//                 fillOpacity: 0.15
//               }}
//             />
            
//             {mapClick && (
//               <Marker
//                 position={mapClick}
//                 icon={{
//                   url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
//                 }}
//               />
//             )}

//             {(mapClick ? nearestProperties : defaultNearestProperties).map((property) => (
//               <Marker
//                 key={property.id}
//                 position={property.position}
//                 onClick={() => setSelectedProperty(property)}
//               />
//             ))}
//           </GoogleMap>
//         </LoadScript>
//       </div>

//       {/* Content Section - Right Side */}
//       <div className="w-1/3 h-full flex flex-col p-4 space-y-4 overflow-y-auto">
//         {/* Filter Section */}
//         <div className="bg-white rounded-lg shadow-md p-4">
//           <h2 className="text-xl font-bold mb-4">Andheri Properties</h2>
//           <div className="mb-4">
//             <label htmlFor="property-type" className="block text-sm font-medium text-gray-700 mb-2">
//               Property Type
//             </label>
//             <select
//               id="property-type"
//               value={filterType}
//               onChange={(e) => setFilterType(e.target.value)}
//               className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
//             >
//               <option value="All">All Property Types</option>
//               <option value="1BHK">1 BHK</option>
//               <option value="2BHK">2 BHK</option>
//               <option value="RK">RK</option>
//               <option value="PG">PG</option>
//             </select>
//           </div>
//         </div>

//         {/* Property Details Section */}
//         {selectedProperty && (
//           <div className="bg-white rounded-lg shadow-md p-4">
//             <h3 className="text-xl font-bold mb-4">{selectedProperty.property_type} Property</h3>
//             <div className="space-y-4">
//               <div className="bg-gray-50 p-3 rounded-lg">
//                 <p className="font-semibold">Address</p>
//                 <p>{selectedProperty.address}</p>
//               </div>
//               <div className="bg-gray-50 p-3 rounded-lg">
//                 <p className="font-semibold">Rent</p>
//                 <p>{selectedProperty.rent}</p>
//               </div>
//               <div className="bg-gray-50 p-3 rounded-lg">
//                 <p className="font-semibold">Occupancy</p>
//                 <p>{selectedProperty.occupancy}</p>
//               </div>
//               <div className="bg-gray-50 p-3 rounded-lg">
//                 <p className="font-semibold">Furnishing Type</p>
//                 <p>{selectedProperty.furnish_type}</p>
//               </div>
//               <div className="bg-gray-50 p-3 rounded-lg">
//                 <p className="font-semibold">Area</p>
//                 <p>{selectedProperty.area}</p>
//               </div>
//               <button 
//                 onClick={() => setShowQuoteForm(true)}
//                 className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
//               >
//                 Get a Quote
//               </button>
//             </div>
//           </div>
//         )}

//         {/* Nearest Properties List */}
//         <div className="bg-white rounded-lg shadow-md p-4">
//           <h3 className="text-xl font-bold mb-4">
//             {mapClick ? 'Nearest Properties' : 'Default Nearest Properties'}
//           </h3>
//           <div className="space-y-4">
//             {(mapClick ? nearestProperties : defaultNearestProperties).map(property => (
//               <div 
//                 key={property.id}
//                 onClick={() => setSelectedProperty(property)}
//                 className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 cursor-pointer transition-colors"
//               >
//                 <div className="flex justify-between items-center mb-2">
//                   <h4 className="font-bold">{property.property_type} Property</h4>
//                   <span className="text-blue-600 font-semibold">{property.rent}</span>
//                 </div>
//                 <div className="text-sm text-gray-600">
//                   <p>Distance: {property.distance.toFixed(2)} km</p>
//                   <p>{property.address}</p>
//                   <p>{property.furnish_type}</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* Quote Form Modal */}
//       {showQuoteForm && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white rounded-lg w-[800px] h-[80vh] relative">
//             <button 
//               onClick={() => setShowQuoteForm(false)}
//               className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
//             >
//               <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <div className="p-4">
//               <h2 className="text-xl font-bold mb-4">Get a Quote</h2>
//               <div className="h-[calc(80vh-8rem)]">
//                 <iframe 
//                   src="https://docs.google.com/forms/d/e/1FAIpQLSf0-4V5rE_445VPbSFciinstcStn5JTxGxVwAwOKAQ0PRvsVQ/viewform?embedded=true"
//                   width="100%"
//                   height="100%"
//                   frameBorder="0"
//                   className="w-full h-full"
//                 >
//                   Loading form...
//                 </iframe>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AndheriRealEstateMap;
// //west

// // Andheri Boundary Coordinates (approximate polygon)
// // const ANDHERI_BOUNDARY = [
// //   { lat: 19.096458, lng: 72.865311 },
// //   { lat: 19.116105, lng: 72.843749 },
// //   { lat: 19.130900, lng: 72.861107 },
// //   { lat: 19.130097, lng: 72.893395 },
// //   { lat: 19.107092, lng: 72.890969 },
// //   { lat: 19.096458, lng: 72.865311 }
// // ];

// // 19.116362, 72.868432



// //center 
//  // 19.139796, 72.824204
// // 19.116362, 72.868432


// // const ANDHERI_BOUNDARY = [
// //   { lat: 19.112386, lng: 72.845490 },
// //   { lat: 19.119361, lng: 72.820256 },
// //   { lat: 19.139309, lng: 72.801545 },
// //   { lat: 19.165886, lng: 72.826094 },
// //   { lat: 19.126497, lng: 72.847207 },
// //   { lat: 19.112386, lng: 72.845490 }
// // ];
// // 19.139796, 72.824204


// import React, { useState, useCallback, useMemo, useRef } from 'react';
// import { LoadScript, GoogleMap, Marker, Polygon } from '@react-google-maps/api';

// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//   const R = 6371;
//   const dLat = (lat2 - lat1) * Math.PI / 180;
//   const dLon = (lon2 - lon1) * Math.PI / 180;
//   const a = 
//     Math.sin(dLat/2) * Math.sin(dLat/2) +
//     Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
//     Math.sin(dLon/2) * Math.sin(dLon/2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
//   return R * c;
// };

// // Location specific configurations
// const locationConfig = {
//   'ANDHERI_WEST': {
//     center: {
//       lat: 19.135695,
//       lng: 72.824579
//     },
//     bounds: {
//       north: 19.1576,
//       south: 19.1190,
//       east: 72.8736,
//       west: 72.8208
//     },
//     boundary: ANDHERI_WEST_BOUNDARY, // You'll need to define this polygon array
//     data: realEstateDataWest // Your west data array
//   },
//   'ANDHERI_EAST': {
//     center: {
//       lat: 19.1190,
//       lng: 72.8736
//     },
//     bounds: {
//       north: 19.1576,
//       south: 19.1190,
//       east: 72.9200,
//       west: 72.8736
//     },
//     boundary: ANDHERI_EAST_BOUNDARY, // You'll need to define this polygon array
//     data: realEstateDataEast // Your east data array
//   }
// };

// const AndheriRealEstateMap = () => {
//   const [selectedProperty, setSelectedProperty] = useState(null);
//   const [nearestProperties, setNearestProperties] = useState([]);
//   const [mapClick, setMapClick] = useState(null);
//   const [filterType, setFilterType] = useState('All');
//   const [showQuoteForm, setShowQuoteForm] = useState(false);
//   const [currentLocation, setCurrentLocation] = useState('ANDHERI_WEST');
//   const mapRef = useRef(null);

//   const mapContainerStyle = {
//     width: '100%',
//     height: '100vh'
//   };

//   const handleMapClick = (event) => {
//     const clickedLat = event.latLng.lat();
//     const clickedLng = event.latLng.lng();
//     setMapClick({ lat: clickedLat, lng: clickedLng });

//     const distances = locationConfig[currentLocation].data
//       .filter(property => filterType === 'All' || property.property_type === filterType)
//       .map(property => ({
//         ...property,
//         distance: calculateDistance(
//           clickedLat, 
//           clickedLng, 
//           property.position.lat, 
//           property.position.lng
//         )
//       }));

//     const nearest = distances
//       .sort((a, b) => a.distance - b.distance)
//       .slice(0, 3);

//     setNearestProperties(nearest);
//   };

//   const defaultNearestProperties = useMemo(() => {
//     const centerDistances = locationConfig[currentLocation].data
//       .filter(property => filterType === 'All' || property.property_type === filterType)
//       .map(property => ({
//         ...property,
//         distance: calculateDistance(
//           locationConfig[currentLocation].center.lat,
//           locationConfig[currentLocation].center.lng,
//           property.position.lat, 
//           property.position.lng
//         )
//       }))
//       .sort((a, b) => a.distance - b.distance)
//       .slice(0, 3);

//     return centerDistances;
//   }, [filterType, currentLocation]);

//   const onMapLoad = useCallback((map) => {
//     mapRef.current = map;
//   }, []);

//   const handleLocationChange = (location) => {
//     setCurrentLocation(location);
//     setSelectedProperty(null);
//     setMapClick(null);
//     setNearestProperties([]);
//     if (mapRef.current) {
//       mapRef.current.panTo(locationConfig[location].center);
//     }
//   };

//   return (
//     <div className="flex flex-col h-screen bg-gray-100">
//       {/* Location Switcher */}
//       <div className="w-full bg-white shadow-md p-4 flex justify-center space-x-4">
//         <button
//           onClick={() => handleLocationChange('ANDHERI_WEST')}
//           className={`px-6 py-2 rounded-md transition-colors ${
//             currentLocation === 'ANDHERI_WEST'
//               ? 'bg-blue-600 text-white'
//               : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
//           }`}
//         >
//           Andheri West
//         </button>
//         <button
//           onClick={() => handleLocationChange('ANDHERI_EAST')}
//           className={`px-6 py-2 rounded-md transition-colors ${
//             currentLocation === 'ANDHERI_EAST'
//               ? 'bg-blue-600 text-white'
//               : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
//           }`}
//         >
//           Andheri East
//         </button>
//       </div>

//       <div className="flex flex-1 overflow-hidden">
//         {/* Map Section - Left Side */}
//         <div className="w-2/3 h-full relative">
//           <LoadScript googleMapsApiKey="AIzaSyDztdh6amKLh1OgAiGKGAp3Y41Q2OJo8J0">
//             <GoogleMap
//               mapContainerStyle={mapContainerStyle}
//               center={locationConfig[currentLocation].center}
//               zoom={12}
//               onClick={handleMapClick}
//               onLoad={onMapLoad}
//               options={{
//                 restriction: {
//                   latLngBounds: locationConfig[currentLocation].bounds,
//                   strictBounds: true
//                 }
//               }}
//             >
//               <Polygon
//                 paths={locationConfig[currentLocation].boundary}
//                 options={{
//                   strokeColor: "#0000FF",
//                   strokeOpacity: 0.8,
//                   strokeWeight: 2,
//                   fillColor: "#0000FF",
//                   fillOpacity: 0.15
//                 }}
//               />
              
//               {mapClick && (
//                 <Marker
//                   position={mapClick}
//                   icon={{
//                     url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
//                   }}
//                 />
//               )}

//               {(mapClick ? nearestProperties : defaultNearestProperties).map((property) => (
//                 <Marker
//                   key={property.id}
//                   position={property.position}
//                   onClick={() => setSelectedProperty(property)}
//                 />
//               ))}
//             </GoogleMap>
//           </LoadScript>
//         </div>

//         {/* Content Section - Right Side */}
//         <div className="w-1/3 h-full flex flex-col p-4 space-y-4 overflow-y-auto">
//           {/* Filter Section */}
//           <div className="bg-white rounded-lg shadow-md p-4">
//             <h2 className="text-xl font-bold mb-4">
//               {currentLocation === 'ANDHERI_WEST' ? 'Andheri West' : 'Andheri East'} Properties
//             </h2>
//             <div className="mb-4">
//               <label htmlFor="property-type" className="block text-sm font-medium text-gray-700 mb-2">
//                 Property Type
//               </label>
//               <select
//                 id="property-type"
//                 value={filterType}
//                 onChange={(e) => setFilterType(e.target.value)}
//                 className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
//               >
//                 <option value="All">All Property Types</option>
//                 <option value="1BHK">1 BHK</option>
//                 <option value="2BHK">2 BHK</option>
//                 <option value="RK">RK</option>
//                 <option value="PG">PG</option>
//               </select>
//             </div>
//           </div>

//           {/* Nearest Properties List */}
//           <div className="bg-white rounded-lg shadow-md p-4">
//             <h3 className="text-xl font-bold mb-4">
//               {mapClick ? 'Nearest Properties' : 'Default Nearest Properties'}
//             </h3>
//             <div className="space-y-4">
//               {(mapClick ? nearestProperties : defaultNearestProperties).map(property => (
//                 <div 
//                   key={property.id}
//                   onClick={() => setSelectedProperty(property)}
//                   className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 cursor-pointer transition-colors"
//                 >
//                   <div className="flex justify-between items-center mb-2">
//                     <h4 className="font-bold">{property.property_type} Property</h4>
//                     <span className="text-blue-600 font-semibold">{property.rent}</span>
//                   </div>
//                   <div className="text-sm text-gray-600">
//                     <p>Distance: {property.distance.toFixed(2)} km</p>
//                     <p>{property.address}</p>
//                     <p>{property.furnish_type}</p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Selected Property Details */}
//           {selectedProperty && (
//             <div className="bg-white rounded-lg shadow-md p-4">
//               <h3 className="text-xl font-bold mb-4">Selected Property</h3>
//               <div className="space-y-4">
//                 <div className="bg-gray-50 p-3 rounded-lg">
//                   <p className="font-semibold">Address</p>
//                   <p>{selectedProperty.address}</p>
//                 </div>
//                 <div className="bg-gray-50 p-3 rounded-lg">
//                   <p className="font-semibold">Rent</p>
//                   <p>{selectedProperty.rent}</p>
//                 </div>
//                 <div className="bg-gray-50 p-3 rounded-lg">
//                   <p className="font-semibold">Occupancy</p>
//                   <p>{selectedProperty.occupancy}</p>
//                 </div>
//                 <div className="bg-gray-50 p-3 rounded-lg">
//                   <p className="font-semibold">Furnishing Type</p>
//                   <p>{selectedProperty.furnish_type}</p>
//                 </div>
//                 <div className="bg-gray-50 p-3 rounded-lg">
//                   <p className="font-semibold">Area</p>
//                   <p>{selectedProperty.area}</p>
//                 </div>
//                 <button 
//                   onClick={() => setShowQuoteForm(true)}
//                   className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
//                 >
//                   Get a Quote
//                 </button>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>

//       {/* Quote Form Modal */}
//       {showQuoteForm && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white rounded-lg w-[800px] h-[80vh] relative">
//             <button 
//               onClick={() => setShowQuoteForm(false)}
//               className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
//             >
//               <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <div className="p-4">
//               <h2 className="text-xl font-bold mb-4">Get a Quote</h2>
//               <div className="h-[calc(80vh-8rem)]">
//                 <iframe 
//                   src="https://docs.google.com/forms/d/e/YOUR_FORM_ID/viewform?embedded=true"
//                   width="100%"
//                   height="100%"
//                   frameBorder="0"
//                   className="w-full h-full"
//                 >
//                   Loading form...
//                 </iframe>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AndheriRealEstateMap;
// import React, { useState } from 'react';
// import locationConfig from './Andheri_West.json'; // Import the JSON file

// function App() {
//   const [selectedArea, setSelectedArea] = useState('ANDHERI_WEST'); // Default to Andheri West

//   const areaData = locationConfig[selectedArea];

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
//       <h1 className="text-2xl font-bold mb-4">Andheri Location Details</h1>
      
//       {/* Buttons for switching */}
//       <div className="mb-6">
//         <button
//           onClick={() => setSelectedArea('ANDHERI_WEST')}
//           className={`px-6 py-2 text-white rounded-md mx-2 ${
//             selectedArea === 'ANDHERI_WEST' ? 'bg-blue-600' : 'bg-gray-400'
//           }`}
//         >
//           Andheri West
//         </button>
//         <button
//           onClick={() => setSelectedArea('ANDHERI_EAST')}
//           className={`px-6 py-2 text-white rounded-md mx-2 ${
//             selectedArea === 'ANDHERI_EAST' ? 'bg-blue-600' : 'bg-gray-400'
//           }`}
//         >
//           Andheri East
//         </button>
//       </div>

//       {/* Display Area Details */}
//       <div className="bg-white p-6 rounded-lg shadow-md w-2/3">
//         <h2 className="text-xl font-semibold mb-4">Center Coordinates</h2>
//         <p>Latitude: {areaData.center.lat}</p>
//         <p>Longitude: {areaData.center.lng}</p>

//         <h2 className="text-xl font-semibold mt-6 mb-4">Boundary Points</h2>
//         <ul className="list-disc ml-6">
//           {areaData.boundary.map((point, index) => (
//             <li key={index}>
//               Lat: {point.lat}, Lng: {point.lng}
//             </li>
//           ))}
//         </ul>

//         <h2 className="text-xl font-semibold mt-6 mb-4">Properties</h2>
//         <ul>
//           {areaData.data.map((property) => (
//             <li key={property.id} className="mb-4">
//               <strong>{property.property_type}</strong> - {property.address}
//               <br />
//               Rent: {property.rent}, Area: {property.area}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default App;
import React, { useState, useCallback, useMemo, useRef ,useEffect } from 'react';
import { LoadScript, GoogleMap , Marker } from '@react-google-maps/api';
// import GoogleAd from "./GoogleAd";

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Earth's radius in km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const AndheriRealEstateMap = () => {
  const [locationConfig, setLocationConfig] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [nearestProperties, setNearestProperties] = useState([]);
  const [mapClick, setMapClick] = useState(null);
  const [filterType, setFilterType] = useState('All');
  const [currentLocation, setCurrentLocation] = useState('ANDHERI_WEST');
  const mapRef = useRef(null);
  const [showQuoteForm, setShowQuoteForm] = useState(false);
 
  useEffect(() => {
    fetch('./Location.json')
      .then(response => response.json())
      .then(data => setLocationConfig(data))
      .catch(error => console.error('Error loading location config:', error));
  }, []);

  // const mapContainerStyle = {
  //   width: '100%',
  //   height: '100vh'
  // };



  const handleMapClick = (event) => {
    if (!locationConfig) return;

    const clickedLat = event.latLng.lat();
    const clickedLng = event.latLng.lng();
    setMapClick({ lat: clickedLat, lng: clickedLng });

    const distances = locationConfig[currentLocation].data
      .filter(property => filterType === 'All' || property.property_type === filterType)
      .map(property => ({
        ...property,
        distance: calculateDistance(
          clickedLat,
          clickedLng,
          property.position.lat,
          property.position.lng
        )
      }));

    const nearest = distances
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 3);

    setNearestProperties(nearest);
  };

  const defaultNearestProperties = useMemo(() => {
    if (!locationConfig) return [];
    const centerDistances = locationConfig[currentLocation].data
      .filter(property => filterType === 'All' || property.property_type === filterType)
      .map(property => ({
        ...property,
        distance: calculateDistance(
          locationConfig[currentLocation].center.lat,
          locationConfig[currentLocation].center.lng,
          property.position.lat,
          property.position.lng
        )
      }))
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 3);

    return centerDistances;
  }, [filterType, currentLocation, locationConfig]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleLocationChange = (location) => {
    setCurrentLocation(location);
    setSelectedProperty(null);
    setMapClick(null);
    setNearestProperties([]);
    if (mapRef.current) {
      mapRef.current.panTo(locationConfig[location].center);
    }
  };

  if (!locationConfig) {
    return <div>Loading...</div>;
  }

return (
  <div className="flex flex-col min-h-screen bg-gray-100">
    {/* Navbar */}
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16 items-center">
          {/* Logo */}
          <div className="flex items-center">
            <div className="w-8 h-8 bg-blue-600 rounded-full flex items-center justify-center">
              <span className="text-white text-lg font-bold">R</span>
            </div>
            <span className="ml-2 text-xl font-bold text-gray-800">Andheri Rentals</span>
          </div>
          
          {/* Contact Link */}
          <a href="tel:+919867386859" className="text-gray-600 hover:text-blue-600 px-3 py-2">
            Contact
          </a>
        </div>
      </div>
    </nav>

    {/* Hero Section */}
    <div className="bg-blue-600 text-white py-6 px-4">
      <h1 className="text-2xl font-bold mb-2 text-center">Find Your Perfect Rental in Andheri</h1>
      <p className="text-blue-100 text-center">Explore properties across Andheri East & West</p>
    </div>

    {/* Location Switcher */}
    <div className="w-full bg-white shadow-md p-4 flex justify-center space-x-4">
      <button
        onClick={() => handleLocationChange('ANDHERI_WEST')}
        className={`px-4 py-2 rounded-md transition-colors text-sm ${
          currentLocation === 'ANDHERI_WEST'
            ? 'bg-blue-600 text-white'
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
        }`}
      >
        Andheri West
      </button>
      <button
        onClick={() => handleLocationChange('ANDHERI_EAST')}
        className={`px-4 py-2 rounded-md transition-colors text-sm ${
          currentLocation === 'ANDHERI_EAST'
            ? 'bg-blue-600 text-white'
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
        }`}
      >
        Andheri East
      </button>
    </div>

    {/* Main Content - Stacked for Mobile */}
    <div className="flex flex-col lg:flex-row flex-1 overflow-hidden">
      {/* Map Section - Full Width on Mobile */}
      <div className="w-full lg:w-2/3 h-64 lg:h-[calc(100vh-250px)] relative">
        <LoadScript googleMapsApiKey="AIzaSyDztdh6amKLh1OgAiGKGAp3Y41Q2OJo8J0">
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '100%',
              minHeight: '800px' // Ensures minimum height on all devices
            }}
            mapContainerClassName="w-full h-full"
            center={locationConfig[currentLocation].center}
            zoom={12} // Increased zoom level for better visibility
            onClick={handleMapClick}
            onLoad={onMapLoad}
            options={{
              restriction: {
                latLngBounds: locationConfig[currentLocation].bounds,
                strictBounds: true
              },
              fullscreenControl: false, // Optional: removes fullscreen button
              mapTypeControl: false, // Optional: removes map type control
              streetViewControl: false // Optional: removes street view control
            }}
          >
            {mapClick && (
              <Marker
                position={mapClick}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                }}
              />
            )}

            {(mapClick ? nearestProperties : defaultNearestProperties).map((property) => (
              <Marker
                key={property.id}
                position={property.position}
                onClick={() => setSelectedProperty(property)}
              />
            ))}
          </GoogleMap>
        </LoadScript>
      </div>

      {/* Content Section - Full Width on Mobile */}
      <div className="w-full lg:w-1/3 flex flex-col p-4 space-y-4 overflow-y-auto">
        {/* Filter Section */}
        <div className="bg-white rounded-lg shadow-md p-4">
          <h2 className="text-xl font-bold mb-4">
            {currentLocation === 'ANDHERI_WEST' ? 'Andheri West' : 'Andheri East'} Properties
          </h2>
          <div className="mb-4">
            <label htmlFor="property-type" className="block text-sm font-medium text-gray-700 mb-2">
              Property Type
            </label>
            <select
              id="property-type"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="All">All Property Types</option>
              <option value="1BHK">1 BHK</option>
              <option value="2BHK">2 BHK</option>
              <option value="RK">RK</option>
              <option value="PG">PG</option>
            </select>
          </div>
        </div>

        {/* Selected Property Details */}
        {selectedProperty && (
          <div className="bg-white rounded-lg shadow-md p-4">
            <h3 className="text-xl font-bold mb-4">Selected Property</h3>
            <div className="space-y-4">
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="font-semibold">Address</p>
                <p className="text-sm">{selectedProperty.address}</p>
              </div>
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="font-semibold">Rent</p>
                <p>{selectedProperty.rent}</p>
              </div>
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="font-semibold">Occupancy</p>
                <p>{selectedProperty.occupancy}</p>
              </div>
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="font-semibold">Furnishing Type</p>
                <p>{selectedProperty.furnish_type}</p>
              </div>
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="font-semibold">Area</p>
                <p>{selectedProperty.area}</p>
              </div>
              <button 
                onClick={() => setShowQuoteForm(true)}
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
              >
                Get a Quote
              </button>
            </div>
          </div>
        )}

        {/* Nearest Properties List */}
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-xl font-bold mb-4">
            {mapClick ? 'Nearest Properties' : 'Default Nearest Properties'}
          </h3>
          <div className="space-y-4">
            {(mapClick ? nearestProperties : defaultNearestProperties).map(property => (
              <div 
                key={property.id}
                onClick={() => setSelectedProperty(property)}
                className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 cursor-pointer transition-colors"
              >
                <div className="flex justify-between items-center mb-2">
                  <h4 className="font-bold text-sm">{property.property_type} Property</h4>
                  <span className="text-blue-600 font-semibold text-sm">{property.rent}</span>
                </div>
                <div className="text-xs text-gray-600">
                  <p>Distance: {property.distance.toFixed(2)} km</p>
                  <p className="truncate">{property.address}</p>
                  <p>{property.furnish_type}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    {/* Footer */}
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company Info */}
          <div className="mb-6 md:mb-0">
            <h3 className="text-lg font-bold mb-4">Andheri Rentals</h3>
            <p className="text-gray-300 text-sm">Finding you the perfect home in Mumbai's most vibrant suburb.</p>
          </div>
          
          {/* Quick Links */}
          <div className="mb-6 md:mb-0">
            <h3 className="text-lg font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="tel:+919867386859" className="text-gray-300 hover:text-white text-sm">Contact</a></li>
            </ul>
          </div>
        
          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-bold mb-4">Contact Us</h3>
            <p className="text-gray-300 text-sm">Email: donaldmisquitta.com</p>
            <p className="text-gray-300 text-sm">Phone: +91 9867386859</p>
            <p className="text-gray-300 text-sm">Address: Andheri, Mumbai - 400093</p>
          </div>
        </div>
        
        {/* Copyright */}
        <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-300">
          <p className="text-sm">&copy; 2024 Fusion Builds. All rights reserved.</p>
        </div>
      </div>
    </footer>

    {/* Quote Form Modal (keep existing modal code) */}
    {showQuoteForm && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg w-[90%] max-w-[800px] h-[80vh] relative">
          <button 
            onClick={() => setShowQuoteForm(false)}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Get a Quote</h2>
            <div className="h-[calc(80vh-8rem)]">
              <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLSf6VO4pnscGzOSzFDrihWmu5jouleYvjSFJNG4sqcd55AtYhg/viewform?usp=sf_link"
                width="100%"
                height="100%"
                frameBorder="0"
                title="Quote Form"
                className="w-full h-full"
              >
                Loading form...
              </iframe>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);
};
  // return (
//     <div className="flex flex-col h-screen bg-gray-100">
//       {/* Location Switcher */}
//       <div className="w-full bg-white shadow-md p-4 flex justify-center space-x-4">
//         <button
//           onClick={() => handleLocationChange('ANDHERI_WEST')}
//           className={`px-6 py-2 rounded-md transition-colors ${
//             currentLocation === 'ANDHERI_WEST'
//               ? 'bg-blue-600 text-white'
//               : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
//           }`}
//         >
//           Andheri West
//         </button>
//         <button
//           onClick={() => handleLocationChange('ANDHERI_EAST')}
//           className={`px-6 py-2 rounded-md transition-colors ${
//             currentLocation === 'ANDHERI_EAST'
//               ? 'bg-blue-600 text-white'
//               : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
//           }`}
//         >
//           Andheri East
//         </button>
//       </div>

      // <div className="flex flex-1 overflow-hidden">
      //   {/* Map Section - Left Side */}
      //   <div className="w-2/3 h-full relative">
      //       <GoogleMap
      //         mapContainerStyle={mapContainerStyle}
      //         center={locationConfig[currentLocation].center}
      //         zoom={1}
      //         onClick={handleMapClick}
      //         onLoad={onMapLoad}
      //         options={{
      //           restriction: {
      //             latLngBounds: locationConfig[currentLocation].bounds,
      //             strictBounds: true
      //           }
      //         }}
      //       >
      //         <Polygon
      //           paths={locationConfig[currentLocation].boundary}
      //           options={{
      //             strokeColor: "#0000FF",
      //             strokeOpacity: 0.8,
      //             strokeWeight: 2,
      //             fillColor: "#0000FF",
      //             fillOpacity: 0.15
      //           }}
      //         />
              
      //         {mapClick && (
      //           <Marker
      //             position={mapClick}
      //             icon={{
      //               url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      //             }}
      //           />
      //         )}

      //         {(mapClick ? nearestProperties : defaultNearestProperties).map((property) => (
      //           <Marker
      //             key={property.id}
      //             position={property.position}
      //             onClick={() => setSelectedProperty(property)}
      //           />
      //         ))}
      //       </GoogleMap>
      //     </LoadScript>
      //   </div>

      //   {/* Content Section - Right Side */}
      //   <div className="w-1/3 h-full flex flex-col p-4 space-y-4 overflow-y-auto">
      //     {/* Filter Section */}
      //     <div className="bg-white rounded-lg shadow-md p-4">
      //       <h2 className="text-xl font-bold mb-4">
      //         {currentLocation === 'ANDHERI_WEST' ? 'Andheri West' : 'Andheri East'} Properties
      //       </h2>
      //       <div className="mb-4">
      //         <label htmlFor="property-type" className="block text-sm font-medium text-gray-700 mb-2">
      //           Property Type
      //         </label>
      //         <select
      //           id="property-type"
      //           value={filterType}
      //           onChange={(e) => setFilterType(e.target.value)}
      //           className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      //         >
      //           <option value="All">All Property Types</option>
      //           <option value="1BHK">1 BHK</option>
      //           <option value="2BHK">2 BHK</option>
      //           {/* <option value="PG">3 BHK</option> */}

      //           <option value="RK">RK</option>
      //           <option value="PG">PG</option>

      //         </select>
      //       </div>
      //     </div>

      //     {/* Nearest Properties List */}
      //     <div className="bg-white rounded-lg shadow-md p-4">
      //       <h3 className="text-xl font-bold mb-4">
      //         {mapClick ? 'Nearest Properties' : 'Default Nearest Properties'}
      //       </h3>
      //       <div className="space-y-4">
      //         {(mapClick ? nearestProperties : defaultNearestProperties).map(property => (
      //           <div 
      //             key={property.id}
      //             onClick={() => setSelectedProperty(property)}
      //             className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 cursor-pointer transition-colors"
      //           >
      //             <div className="flex justify-between items-center mb-2">
      //               <h4 className="font-bold">{property.property_type} Property</h4>
      //               <span className="text-blue-600 font-semibold">{property.rent}</span>
      //             </div>
      //             <div className="text-sm text-gray-600">
      //               <p>Distance: {property.distance.toFixed(2)} km</p>
      //               <p>{property.address}</p>
      //               <p>{property.furnish_type}</p>
      //             </div>
      //           </div>
      //         ))}
      //       </div>
      //     </div>

      //     {/* Selected Property Details */}
      //     {selectedProperty && (
      //       <div className="bg-white rounded-lg shadow-md p-4">
      //         <h3 className="text-xl font-bold mb-4">Selected Property</h3>
      //         <div className="space-y-4">
      //           <div className="bg-gray-50 p-3 rounded-lg">
      //             <p className="font-semibold">Address</p>
      //             <p>{selectedProperty.address}</p>
      //           </div>
      //           <div className="bg-gray-50 p-3 rounded-lg">
      //             <p className="font-semibold">Rent</p>
      //             <p>{selectedProperty.rent}</p>
      //           </div>
      //           <div className="bg-gray-50 p-3 rounded-lg">
      //             <p className="font-semibold">Occupancy</p>
      //             <p>{selectedProperty.occupancy}</p>
      //           </div>
      //           <div className="bg-gray-50 p-3 rounded-lg">
      //             <p className="font-semibold">Furnishing Type</p>
      //             <p>{selectedProperty.furnish_type}</p>
      //           </div>
      //           <div className="bg-gray-50 p-3 rounded-lg">
      //             <p className="font-semibold">Area</p>
      //             <p>{selectedProperty.area}</p>
      //           </div>
      //           <button 
      //             onClick={() => setShowQuoteForm(true)}
      //             className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
      //           >
      //             Get a Quote
      //           </button>
      //         </div>
      //       </div>
      //     )}
      //   </div>
      // </div>

//       {/* Quote Form Modal */}
//       {showQuoteForm && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//           <div className="bg-white rounded-lg w-[800px] h-[80vh] relative">
//             <button 
//               onClick={() => setShowQuoteForm(false)}
//               className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
//             >
//               <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//             <div className="p-4">
//               <h2 className="text-xl font-bold mb-4">Get a Quote</h2>
//               <div className="h-[calc(80vh-8rem)]">
//                 <iframe 
//                   src="https://docs.google.com/forms/d/e/YOUR_FORM_ID/viewform?embedded=true"
//                   width="100%"
//                   height="100%"
//                   frameBorder="0"
//                   className="w-full h-full"
//                 >
//                   Loading form...
//                 </iframe>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>

//   );
// };

export default AndheriRealEstateMap;

